import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AppWrapper } from './Styles/AppWrapper'
import { ThemeProvider } from 'styled-components'
import { Theme } from './Styles/Theme'
import { TopBar } from './Components/TopBar'
import { Home } from './Views/Home'
import { Markets } from './Views/Markets'

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <AppWrapper>
        <Router>
          <TopBar />
          <Switch>
            <Route path="/markets">
              <Markets />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </AppWrapper>
    </ThemeProvider>
  )
}

export default App
