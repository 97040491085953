import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { ToggleSwitch } from '../../../Components/ToggleSwitch'

const MarketsOverviewWrapper = styled.div`
  padding: 2rem 4rem;
`
const MarketRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  background: ${(p) => p.theme.grey600};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 1.5rem 2rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.2);

  &:hover,
  &.active {
    box-shadow: 0 0 33px -22px ${(p) => p.theme.primaryColor};
  }
`

const MarketRowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    text-transform: uppercase;
    color: ${(props) => props.theme.primaryColor};
    font-size: 0.8rem;
    opacity: 0.8;
    margin-bottom: 0.25rem;
    text-align: right;
    width: 100%;
  }

  > .body {
    text-align: right;
    width: 100%;
  }
`

const MarketRowItemHorizontal = styled(MarketRowItem)`
  flex-direction: row;

  > * {
    margin: 0 0.5rem;
  }
`

const MarketRowItemAction = styled.span`
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.primaryColor};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 2px solid ${(props) => props.theme.primaryColor};
  transition: background 0.3s;

  &:hover {
    color: #000;
    background: ${(props) => props.theme.primaryColor};
  }
`

const MarketRowMock = () => {
  const [collateral, setCollateral] = useState(true)

  return (
    <MarketRow>
      <MarketRowItem>
        <div className="title">Asset</div>
        <div className="body">Bitcoin</div>
      </MarketRowItem>
      <MarketRowItem>
        <div className="title">Balance</div>
        <div className="body">12.23214</div>
      </MarketRowItem>
      <MarketRowItem>
        <div className="title">Wallet Balance</div>
        <div className="body">23.2441</div>
      </MarketRowItem>
      <MarketRowItem>
        <div className="title">Deposit APY</div>
        <div className="body">120 %</div>
      </MarketRowItem>
      <MarketRowItem>
        <div className="title">Collateral</div>
        <div className="body">
          <ToggleSwitch active={collateral} toggle={() => setCollateral((st) => !st)} />
        </div>
      </MarketRowItem>
      <MarketRowItemHorizontal>
        <MarketRowItemAction>Deposit</MarketRowItemAction>
        <MarketRowItemAction>Withdraw</MarketRowItemAction>
      </MarketRowItemHorizontal>
    </MarketRow>
  )
}

export const MarketsOverview = () => {
  return (
    <MarketsOverviewWrapper>
      <MarketRowMock />
      <MarketRowMock />
      <MarketRowMock />
      <MarketRowMock />
      <MarketRowMock />
    </MarketsOverviewWrapper>
  )
}
