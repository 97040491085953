// Color name style is equal to Material Design - Being 500 the standard
export const Theme = {
  fontFamily:
    "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  primaryColor: '#00e676',
  secondaryColor: '#fff',
  dangerColor: '#de4437',
  grey300: '#303340',
  grey400: '#242732',
  grey500: '#1f2127',
  grey600: '#191a21',
  green500: '#38f997f0',
  yellow500: '#fdd835',
  pink500: '#e91e63',
  blue500: '#29b6f6',
  borderRadius: '5px',
  boxShadow: '0 0 15px 0 rgb(0 0 0 / 15%)',
  breakpoints: {
    xs: 'max-width: 575px',
    sm: 'min-width: 576px',
    lg: 'min-width: 992px',
    untilLg: 'max-width: 992px',
    untilSm: 'max-width: 576px'
  }
}

export interface Themed {
  theme: typeof Theme
}
