import React from 'react'
import { MarketsOverview } from './Components/MarketsOverview'
import {
  MarketsStats,
  MarketStateBody,
  MarketStatTitle,
  MarketStatWrapper,
  MarketsWrapper
} from './Styles'

export const Markets = () => {
  return (
    <MarketsWrapper>
      <MarketsStats>
        <MarketStatWrapper>
          <MarketStatTitle>Total Market Size</MarketStatTitle>
          <MarketStateBody>${(12312144.21).toLocaleString()}</MarketStateBody>
        </MarketStatWrapper>
        <MarketStatWrapper>
          <MarketStatTitle>Your Supply APY</MarketStatTitle>
          <MarketStateBody>120%</MarketStateBody>
        </MarketStatWrapper>
        <MarketStatWrapper>
          <MarketStatTitle>Your Borrow Balance</MarketStatTitle>
          <MarketStateBody>${(12312.21).toLocaleString()}</MarketStateBody>
        </MarketStatWrapper>
        <MarketStatWrapper>
          <MarketStatTitle>Your Borrow APR</MarketStatTitle>
          <MarketStateBody>2%</MarketStateBody>
        </MarketStatWrapper>
      </MarketsStats>
      <MarketsOverview />
    </MarketsWrapper>
  )
}
