import styled from 'styled-components'

export const HomeWrapper = styled.div`
  background: ${(props) => props.theme.grey500};
`

export const TotalValueLockedWrapper = styled.span`
  position: absolute;
  width: 40rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;

  h1 {
    font-weight: 900;
    font-size: 4rem;
    margin: 0.5rem;
  }
`

export const TVLAction = styled.div`
  margin: 1rem;
  margin-top: 4rem;
  display: flex;
  justify-content: space-around;
`

export const LendButton = styled.span`
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  background: transparent;
  transition: background 0.3s;

  &:hover {
    background: #fff;
    color: ${(props) => props.theme.grey600};
  }
`

export const BorrowButton = styled(LendButton)``

export const WhatIs = styled.div`
  margin: 8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    display: inline;
  }
`

export const WhatIsBody = styled.div`
  width: 50vw;
  line-height: 2rem;
  text-align: justify;
  margin: 2rem 0;
`

export const CallActionButton = styled.span`
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 2px solid ${(props) => props.theme.primaryColor};
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`

export const MarketsTitle = styled.div`
  h1 {
    margin: 0;
    text-align: center;
    color: ${(props) => props.theme.primaryColor};
  }
`
