import styled from 'styled-components'

export const AppWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: ${(props) => props.theme.grey};
  color: #fff;

  a {
    color: ${(props) => props.theme.primaryColor};
  }
  /* Text helpers */
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  /* Breakpoint helpers */
  .hide-lg {
    display: none;
  }

  @media (${(p) => p.theme.breakpoints.lg}) {
    td.hide-lg,
    th.hide-lg {
      display: table-cell;
    }

    td.show-sm,
    th.show-sm {
      display: none;
    }
  }
`
