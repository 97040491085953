const TVL_ENDPOINT = 'https://data.unifi.report/api/total-information'

export const useTVL = () => {
  const getTVL = () => {
    return fetch(TVL_ENDPOINT)
      .then((res) => res.json())
      .then((res) => Number(res[0].total_value_locked))
  }

  return { getTVL }
}
