import React from 'react'
import styled from 'styled-components'

const StakedOnlyWrapper = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
`

const SelectorWrapper = styled.div`
  margin-left: 1rem;
  background: ${(props) => props.theme.grey400};
  position: relative;
  width: 3rem;
  height: 1.3rem;
  border-radius: 1rem;
  cursor: pointer;
`

const SelectorBall = styled.span<{ active: boolean }>`
  background-color: ${(props) => (props.active ? props.theme.primaryColor : '#fff')};
  border-radius: 50%;
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: 0;
  left: ${(props) => (props.active ? '0%' : 'calc(100% - 1.3rem)')};
  transition: left 0.3s ease-out;
`

export const ToggleSwitch: React.FC<{ active: boolean; toggle: () => void }> = ({
  children,
  active,
  toggle
}) => {
  return (
    <StakedOnlyWrapper>
      <span>{children}</span>
      <SelectorWrapper onClick={toggle}>
        <SelectorBall {...{ active }} />
      </SelectorWrapper>
    </StakedOnlyWrapper>
  )
}
