import React, { useMemo } from 'react'
import { TopBarWrapper, TopBarNavWrapper, NavLink, WalletWrapper } from './Styles'
import { ReactComponent as Logo } from '../../Assets/LogoText.svg'
import { useHistory, useLocation } from 'react-router-dom'

export const TopBar = () => {
  const location = useLocation()
  const history = useHistory()

  const isHome = useMemo(() => {
    return !!location.pathname.match(/^\/$/)
  }, [location.pathname])

  return (
    <TopBarWrapper isHome={isHome}>
      <Logo />
      <TopBarNavWrapper>
        <NavLink onClick={() => history.push('/')}>Home</NavLink>
        <NavLink onClick={() => history.push('/markets')}>Markets</NavLink>
        <NavLink>Dashboard</NavLink>
        <NavLink>FAQ</NavLink>
        <WalletWrapper>Connect a Wallet</WalletWrapper>
      </TopBarNavWrapper>
    </TopBarWrapper>
  )
}
