import styled from 'styled-components'

export const MarketsWrapper = styled.div`
  background: ${(props) => props.theme.grey500};
  min-height: 100vh;
`

export const MarketsStats = styled.div`
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const MarketStatWrapper = styled.span`
  margin: 0 0.5rem;
  min-width: 20rem;
  max-width: 20rem;
  height: 6rem;
  padding: 1rem;
  border-radius: ${(props) => props.theme.borderRadius};
  background: ${(props) => props.theme.grey600};
`

export const MarketStatTitle = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.primaryColor};
  font-size: 0.8rem;
  opacity: 0.8;
  margin-bottom: 0.5rem;
`

export const MarketStateBody = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
`
