import styled from 'styled-components'

export const TopBarWrapper = styled.div<{ isHome: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  position: ${(props) => (props.isHome ? 'absolute' : 'relative')};
  z-index: 99999999;
  background: ${(props) => props.theme.grey600};

  svg {
    width: 5rem;
    height: auto;
  }
`

export const TopBarNavWrapper = styled.div``

export const NavLink = styled.span`
  cursor: pointer;
  padding: 0 1rem;
`

export const WalletWrapper = styled(NavLink)`
  margin-left: 1rem;
  padding: 0.25rem 1rem;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.primaryColor};
`
