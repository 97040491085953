import React from 'react'
import styled from 'styled-components'
import ParticlesLib from 'react-particles-js'

const ParticlesWrapper = styled.div`
  color: #fff;
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.grey500};
  position: relative;
`

export const Particles: React.FC = ({ children }) => {
  return (
    <ParticlesWrapper>
      <ParticlesLib
        params={{
          particles: {
            number: {
              value: 30
            },
            size: {
              value: 3
            }
          }
        }}
      />
      {children}
    </ParticlesWrapper>
  )
}
